@import "variables";

.progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 16px;
  padding: 0 18px;
  background: rgba(255, 255, 255, .1);
  .progress {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 32px;
    background: rgba(254, 65, 65, .78);
    border-radius: 16px;
  }
  .text {
    display: flex;
    color: #fff;
    .percent {
      width: 38px;
    }
  }
  &.highlight {
    .progress {
      box-shadow: 0 2px 13px 0 #E04141;
    }
  }
}
