@import "variables";

.icon-arrow:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right {
  display: inline-block;
  transform: rotate(180deg);
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-top {
  transform: rotate(90deg);
}
.icon-arrow-top:before {
  content: "\e901";
}
.icon-arrow-bottom {
  transform: rotate(270deg);
}
.icon-arrow-bottom:before {
  content: "\e901";
}

.icon-chevron-down {
  &:before {
    content: "\e90f";
  }
}
.icon-chevron-up {
  display: inline-block;
  transform: rotate(180deg);
  &:before {
    content: "\e90f";
  }
}
.icon-chevron-right {
  display: inline-block;
  transform: rotate(180deg);
  &:before {
    content: "\e915";
  }
}
.icon-caret-down {
  display: inline-block;
  transform: rotate(270deg);
  &:before {
    content: "\e915";
  }
}
.icon-caret-up {
  display: inline-block;
  transform: rotate(90deg);
  &:before {
    content: "\e915";
  }
}

.circle-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 5px;
  background: $primaryColor;
  color: #fff;
  font-size: 6px;
  text-align: center;
  line-height: 20px;
}
