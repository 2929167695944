@import "mixins";
@import "variables";

body {
  color: $textColor;
  @include text();
  font-variant-numeric: tabular-nums;
}
a {
  text-decoration: none;
  color: $textMutedColor;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $textColor;
  }
}
.link {
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: -5px;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: $poppyColor;
  }
}
.h1,
h1 {
  @include h1();
}
.h2,
h2 {
  @include h2();
}
.h3,
h3 {
  @include h3();
}
.h4,
h4 {
  @include h4();
}
.h5,
h5 {
  @include h5();
}
p {
  margin-top: 0;
  margin-bottom: 16px;
}
.text-color-primary,
.text-color-poppy {
  color: $poppyColor;
  &:hover {
    color: $poppyColor;
  }
}
.text-color-alert {
  color: $alertColor;
  &:hover {
    color: $alertColor;
  }
}
.text-color-grey {
  color: $greyColor;
}
.text-color-muted {
  color: $textMutedColor;
}
.text-color {
  color: $textColor;
  &:hover {
    color: $textColor;
  }
}
.text-bold {
  font-weight: bold;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-small {
  @include smallText();
}
.text-micro {
  @include microText();
}
