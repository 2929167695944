@import 'variables';
@import 'mixins';

.btn {
  display: inline-block;
  outline: none;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
}
.btn-link {
  font-size: 18px;
  color: $primaryColor;
  text-transform: uppercase;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 0 0 2px;
  &:disabled {
    color: $textMutedColor;
  }
}
a.btn-link,
button.btn-link {
  cursor: pointer;
  &:hover {
    color: $primaryColor;
    border-bottom: 2px solid $primaryColor;
    &:disabled,
    &.text-muted {
      color: $textMutedColor;
      border-color: $textMutedColor;
    }
  }
}
.btn-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 0;
  border-radius: 28px;
  background-color: $primaryColor;
  transition: transform ease 300ms;
  font-size: 10px;
  color: #fff;
  &:hover {
    transform: scale(1.2);
  }
  &.muted,
  &:disabled {
    color: $textMutedColor;
    background: rgba(255, 255, 255, .1);
  }
  &.transparent {
    background-color: rgba(255, 255, 255, .3);
  }
  &.graphite{
    background-color: $graphiteColor;
  }
}
.btn-group {
  display: flex;
  align-items: stretch;
  .btn {
    &:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &+.btn {
      margin-left: 1px;
    }
  }
}
.btn-transparent {
  padding: 5px 13px;
  background: rgba(255, 255, 255, .3);
  border-radius: 4px;
  border: none;
  color: #fff;
  &:hover {
    background: rgba(255, 255, 255, .5);
  }
}

.btn-primary {
  padding: 8px 15px;
  border-radius: 100px;
  background: $primaryColor;
  color: #fff;
  border: none;
  &:disabled {
    background: $textMutedColor;
  }
}

.button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  padding: 5px 13px;
  border-radius: 100px;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  text-align: center;

  &:active,
  &:focus {
    outline: none;
  }
  &.loading {
    text-indent: -100000px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -8px 0 0 -8px;
      width: 17px;
      height: 17px;
      border: 2px solid rgba(255, 255, 255, .25);
      border-left-color: #fff;
      border-right-color: #fff;
      border-radius: 50%;
      animation: rotate 2s linear infinite;
    }
  }
}
.button--color-primary {
  min-width: 100px;
  background: $poppyColor;
  &:hover:not(:disabled),
  &.loading,
  &.active {
    background: $alertColor;
  }
  &:disabled {
    background: $textMutedColor;
  }
}
.button--color-default {
  min-width: 150px;
  background: $graphiteColor;
  &:hover:not(:disabled),
  &.loading,
  &.active {
    background: $pebbleColor;
  }
}
.button--color-secondary {
  min-width: 150px;
  background: #313241;
}
.button--size-lg {
  padding: 25px;
}
.button--size-md {
  padding: 14px 16px;
}
.button--size-sm {
  padding: 10px 16px;
  @include smallText();
  font-weight: bold;
}
.button--size-xs {
  min-width: auto;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bold;
}
.button--type-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  min-width: auto;
  &.button--size-sm {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  &.button--size-md {
    width: 36px;
    height: 36px;
  }
  &.button--size-lg {
    width: 48px;
    height: 48px;
  }
}
.button--type-link {
  padding: 0;
  border-radius: 0;
  background: transparent;
  text-transform: none;
  text-align: center;
  &.button--color-default {
    color: $greyColor;
    &:hover {
      background: transparent;
      color: $pebbleColor;
    }
  }
  &.button--color-primary {
    color: $poppyColor;
    &:hover {
      background: transparent;
      color: $alertColor;
    }
  }
}

.button-group {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  .button {
    flex: 1 0;
    min-width: auto;
    &:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &+.button {
      margin-left: 1px;
    }
  }
}

// TODO move to tournament form
@media (max-width: $phoneBreakpoint) {
  .tournament-form {
    .button-group {
      display: block;
      button {
        width: 100%;
        text-align: left;
      }
    }
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
