@import "variables";

.owl-dt-inline {
  display: block;
}
.owl-dt-calendar-main {
  padding: 0;
}
.owl-dt-container {
  min-width: 100%;
  display: block;
  background: $inputBackgroundColor;
  font-size: 12px;
  color: #fff;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  .owl-dt-calendar {
    height: auto;
  }
  .owl-dt-calendar-control {
    border-radius: 4px;
    margin: 0 0 11px;
    color: #fff;
    background: $table-border-color;
  }
  .owl-dt-control-period-button .owl-dt-control-button-arrow {
    display: none;
  }
  .owl-dt-calendar-table {
    .owl-dt-calendar-header {
      color: #fff;
      .owl-dt-calendar-table-divider {
        height: 0;
        padding: 0;
        &:after {
          display: none;
        }
      }
      .owl-dt-weekdays th {
        font-size: 12px;
      }
    }
    .owl-dt-calendar-cell {
      color: #fff;
    }
    .owl-dt-calendar-cell-content {
      font-size: 12px;
      background: $table-border-color;
      border-radius: 0;
    }
    .owl-dt-calendar-cell-selected {
      background: $primaryColor;
    }
    .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border: 1px solid $primaryColor;
    }
    .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
      box-shadow: none;
    }
    .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
    :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected):not(.owl-dt-calendar-cell-out) {
      background: $primaryColor;
    }
    .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      color: $textMutedColor;
    }
  }
}
