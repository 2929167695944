@import "variables";

@mixin h1() {
  font: bold 44px/56px $font-family-base;
}
@mixin h2() {
  font: bold 32px/40px $font-family-base;
}
@mixin h3() {
  font: bold 24px/32px $font-family-base;
}
@mixin h4() {
  font: bold 20px/19px $font-family-base;
}
@mixin h5() {
  font: bold 18px/24px $font-family-base;
}
@mixin text() {
  font: 14px/20px $font-family-base;
  letter-spacing: 0.02em;
}
@mixin textBold() {
  font: bold 14px/20px $font-family-base;
}
@mixin smallText() {
  font: 12px/16px $font-family-base;
}
@mixin microText() {
  font: 10/12px $font-family-base;
}
@mixin clear() {
  &:before,
  &:after {
    content: '';
    display: table;
    height: 0;
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}
@mixin centered-flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin centered-absolute() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@mixin panel() {
  background: $carbonColor;
  border-radius: 8px;
}
@mixin icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin icon-select() {
  @include icon();
  content: "\e90f";
}
@mixin icon-image() {
  @include icon();
  content: "\e90a";
}
@mixin icon-load() {
  @include icon();
  content: "\e923";
}
@mixin icon-award-left() {
  @include icon();
  content: "\e902";
}
@mixin icon-award-right() {
  @include icon();
  content: "\e903";
}
@mixin icon-ok() {
  @include icon();
  content: "\e90d";
}
@mixin icon-close() {
  @include icon();
  content: "\e905";
}
