@import "variables";
@import "mixins";

.section {
  margin-top: 60px;
  > .header {
    display: flex;
    align-items: center;
    margin: 0 0 23px;
    .title {
      @include h3();
    }
    .btn,
    .btn-circle {
      margin-left: 15px;
      i {
        font-size: 12px;
        &+span {
          margin-left: 5px;
        }
      }
    }
    .archive {
      margin-left: auto;
    }
  }
}

.empty-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 192px;
  .icon {
    margin: 0 0 8px;
    font-size: 48px;
    color: $pebbleColor;
  }
  .title {
    margin: 0 0 5px;
    color: $pebbleColor;
  }
  .btn-link {
    border-bottom: 1px dashed $poppyColor;
    padding-bottom: 2px;
    text-transform: none;
    color: $poppyColor;
    font-size: 14px;
    &:hover {
      border-bottom: 1px dashed $poppyColor;
    }
  }
}

.empty-slider {
  display: flex;
  justify-content: space-between;

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc((100% - 40px)/3);
    height: 218px;
    padding: 30px;
    text-align: center;

    &:first-child {
      background: linear-gradient(90deg, $body-bg 0%, $secondaryBackgroundColor 100%);
      box-shadow: 0 20px 29px 0 rgba(0,0,0,0.15);
    }

    &:last-child {
      background: linear-gradient(270deg, $body-bg 0%, $secondaryBackgroundColor 100%);
      box-shadow: 0 20px 29px 0 rgba(0,0,0,0.15);
    }
  }
}

.create-item {
  width: 252px;
  border: 1px solid $secondaryBackgroundColor;
  border-radius: 4px;
  box-shadow: 0 20px 29px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  color: $textMutedColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.primary {
    .btn-circle {
      color: #fff;
      background: $primaryColor;
    }
  }

  .btn-circle {
    width: 67px;
    height: 67px;
    margin: 0 0 29px;
    border-radius: 67px;
    background-color: $secondaryBackgroundColor;
    font-size: 26px;
    color: rgba(255, 255, 255, .2);
  }

  &:hover {
    color: $textColor;
    .btn-circle {
      transform: scale(1.2);
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .section {
    margin-top: 30px;
    > .header {
      justify-content: space-between;
    }
  }
  .empty-section {
    text-align: center;
  }

  .create-item {
    width: 190px;
  }
  .empty-slider {
    .panel {
      width: 100%;
      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }
}
