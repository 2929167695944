@import "variables";

.visible-mobile {
  display: none!important;;
}

@media (max-width: $phoneBreakpoint) {
  .visible-mobile {
    display: inherit!important;;
  }
  .hidden-mobile {
    display: none!important;
  }
}
