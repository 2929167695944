@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~@angular/cdk/text-field-prebuilt.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~croppie/croppie.css";
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
@import "./shared/fonts/style.css";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: #fff;
  text-align: left;
  background-color: #161721;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bold;
}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
body {
  color: #fff;
  font: 14px/20px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.02em;
  font-variant-numeric: tabular-nums;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
a:hover {
  text-decoration: none;
  color: #fff;
}

.link {
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: -5px;
  border-bottom: 1px solid transparent;
}
.link:hover {
  border-color: #E04141;
}

.h1,
h1 {
  font: bold 44px/56px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.h2,
h2 {
  font: bold 32px/40px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.h3,
h3 {
  font: bold 24px/32px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.h4,
h4 {
  font: bold 20px/19px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.h5,
h5 {
  font: bold 18px/24px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

.text-color-primary,
.text-color-poppy {
  color: #E04141;
}
.text-color-primary:hover,
.text-color-poppy:hover {
  color: #E04141;
}

.text-color-alert {
  color: #C82A2A;
}
.text-color-alert:hover {
  color: #C82A2A;
}

.text-color-grey {
  color: #6F727C;
}

.text-color-muted {
  color: rgba(255, 255, 255, 0.5);
}

.text-color {
  color: #fff;
}
.text-color:hover {
  color: #fff;
}

.text-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-small {
  font: 12px/16px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.text-micro {
  font: 10/12px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.panel {
  background: #242531;
  border-radius: 8px;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.badge {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #E04141;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.icon-arrow:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-right {
  display: inline-block;
  transform: rotate(180deg);
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-top {
  transform: rotate(90deg);
}

.icon-arrow-top:before {
  content: "";
}

.icon-arrow-bottom {
  transform: rotate(270deg);
}

.icon-arrow-bottom:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-up {
  display: inline-block;
  transform: rotate(180deg);
}
.icon-chevron-up:before {
  content: "";
}

.icon-chevron-right {
  display: inline-block;
  transform: rotate(180deg);
}
.icon-chevron-right:before {
  content: "";
}

.icon-caret-down {
  display: inline-block;
  transform: rotate(270deg);
}
.icon-caret-down:before {
  content: "";
}

.icon-caret-up {
  display: inline-block;
  transform: rotate(90deg);
}
.icon-caret-up:before {
  content: "";
}

.circle-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 5px;
  background: #E04141;
  color: #fff;
  font-size: 6px;
  text-align: center;
  line-height: 20px;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -13px;
}
.form-row .col {
  padding: 0 13px;
}

.form-control {
  margin: 0 0 25px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (max-width: 1050px) {
  .form-row {
    flex-direction: column;
  }
  .form-row .col {
    flex: 1 auto;
    width: 100%;
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.btn {
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.btn:active, .btn:focus {
  outline: none;
}

.btn-link {
  font-size: 18px;
  color: #E04141;
  text-transform: uppercase;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 0 0 2px;
}
.btn-link:disabled {
  color: rgba(255, 255, 255, 0.5);
}

a.btn-link,
button.btn-link {
  cursor: pointer;
}
a.btn-link:hover,
button.btn-link:hover {
  color: #E04141;
  border-bottom: 2px solid #E04141;
}
a.btn-link:hover:disabled, a.btn-link:hover.text-muted,
button.btn-link:hover:disabled,
button.btn-link:hover.text-muted {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 0;
  border-radius: 28px;
  background-color: #E04141;
  transition: transform ease 300ms;
  font-size: 10px;
  color: #fff;
}
.btn-circle:hover {
  transform: scale(1.2);
}
.btn-circle.muted, .btn-circle:disabled {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
}
.btn-circle.transparent {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn-circle.graphite {
  background-color: #313241;
}

.btn-group {
  display: flex;
  align-items: stretch;
}
.btn-group .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group .btn + .btn {
  margin-left: 1px;
}

.btn-transparent {
  padding: 5px 13px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  border: none;
  color: #fff;
}
.btn-transparent:hover {
  background: rgba(255, 255, 255, 0.5);
}

.btn-primary {
  padding: 8px 15px;
  border-radius: 100px;
  background: #E04141;
  color: #fff;
  border: none;
}
.btn-primary:disabled {
  background: rgba(255, 255, 255, 0.5);
}

.button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  padding: 5px 13px;
  border-radius: 100px;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.button:active, .button:focus {
  outline: none;
}
.button.loading {
  text-indent: -100000px;
  position: relative;
}
.button.loading:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -8px 0 0 -8px;
  width: 17px;
  height: 17px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-left-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;
  animation: rotate 2s linear infinite;
}

.button--color-primary {
  min-width: 100px;
  background: #E04141;
}
.button--color-primary:hover:not(:disabled), .button--color-primary.loading, .button--color-primary.active {
  background: #C82A2A;
}
.button--color-primary:disabled {
  background: rgba(255, 255, 255, 0.5);
}

.button--color-default {
  min-width: 150px;
  background: #313241;
}
.button--color-default:hover:not(:disabled), .button--color-default.loading, .button--color-default.active {
  background: #525562;
}

.button--color-secondary {
  min-width: 150px;
  background: #313241;
}

.button--size-lg {
  padding: 25px;
}

.button--size-md {
  padding: 14px 16px;
}

.button--size-sm {
  padding: 10px 16px;
  font: 12px/16px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
}

.button--size-xs {
  min-width: auto;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bold;
}

.button--type-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  min-width: auto;
}
.button--type-circle.button--size-sm {
  width: 24px;
  height: 24px;
  font-size: 12px;
}
.button--type-circle.button--size-md {
  width: 36px;
  height: 36px;
}
.button--type-circle.button--size-lg {
  width: 48px;
  height: 48px;
}

.button--type-link {
  padding: 0;
  border-radius: 0;
  background: transparent;
  text-transform: none;
  text-align: center;
}
.button--type-link.button--color-default {
  color: #6F727C;
}
.button--type-link.button--color-default:hover {
  background: transparent;
  color: #525562;
}
.button--type-link.button--color-primary {
  color: #E04141;
}
.button--type-link.button--color-primary:hover {
  background: transparent;
  color: #C82A2A;
}

.button-group {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.button-group .button {
  flex: 1 0;
  min-width: auto;
}
.button-group .button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button-group .button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.button-group .button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.button-group .button + .button {
  margin-left: 1px;
}

@media (max-width: 700px) {
  .tournament-form .button-group {
    display: block;
  }
  .tournament-form .button-group button {
    width: 100%;
    text-align: left;
  }
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.data-list {
  display: flex;
  flex-direction: column;
}
.data-list > .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 30px;
}
.data-list > .item:hover {
  background-color: #E04141;
}

@media (max-width: 1050px) {
  .data-list > .item {
    padding: 13px 20px;
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.section {
  margin-top: 60px;
}
.section > .header {
  display: flex;
  align-items: center;
  margin: 0 0 23px;
}
.section > .header .title {
  font: bold 24px/32px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.section > .header .btn,
.section > .header .btn-circle {
  margin-left: 15px;
}
.section > .header .btn i,
.section > .header .btn-circle i {
  font-size: 12px;
}
.section > .header .btn i + span,
.section > .header .btn-circle i + span {
  margin-left: 5px;
}
.section > .header .archive {
  margin-left: auto;
}

.empty-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 192px;
}
.empty-section .icon {
  margin: 0 0 8px;
  font-size: 48px;
  color: #525562;
}
.empty-section .title {
  margin: 0 0 5px;
  color: #525562;
}
.empty-section .btn-link {
  border-bottom: 1px dashed #E04141;
  padding-bottom: 2px;
  text-transform: none;
  color: #E04141;
  font-size: 14px;
}
.empty-section .btn-link:hover {
  border-bottom: 1px dashed #E04141;
}

.empty-slider {
  display: flex;
  justify-content: space-between;
}
.empty-slider .panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc((100% - 40px)/3);
  height: 218px;
  padding: 30px;
  text-align: center;
}
.empty-slider .panel:first-child {
  background: linear-gradient(90deg, #161721 0%, #222330 100%);
  box-shadow: 0 20px 29px 0 rgba(0, 0, 0, 0.15);
}
.empty-slider .panel:last-child {
  background: linear-gradient(270deg, #161721 0%, #222330 100%);
  box-shadow: 0 20px 29px 0 rgba(0, 0, 0, 0.15);
}

.create-item {
  width: 252px;
  border: 1px solid #222330;
  border-radius: 4px;
  box-shadow: 0 20px 29px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.create-item.primary .btn-circle {
  color: #fff;
  background: #E04141;
}
.create-item .btn-circle {
  width: 67px;
  height: 67px;
  margin: 0 0 29px;
  border-radius: 67px;
  background-color: #222330;
  font-size: 26px;
  color: rgba(255, 255, 255, 0.2);
}
.create-item:hover {
  color: #fff;
}
.create-item:hover .btn-circle {
  transform: scale(1.2);
}

@media (max-width: 1050px) {
  .section {
    margin-top: 30px;
  }
  .section > .header {
    justify-content: space-between;
  }

  .empty-section {
    text-align: center;
  }

  .create-item {
    width: 190px;
  }

  .empty-slider .panel {
    width: 100%;
  }
  .empty-slider .panel:first-child, .empty-slider .panel:last-child {
    display: none;
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.owl-dt-inline {
  display: block;
}

.owl-dt-calendar-main {
  padding: 0;
}

.owl-dt-container {
  min-width: 100%;
  display: block;
  background: #2B2C38;
  font-size: 12px;
  color: #fff;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
}
.owl-dt-container .owl-dt-calendar {
  height: auto;
}
.owl-dt-container .owl-dt-calendar-control {
  border-radius: 4px;
  margin: 0 0 11px;
  color: #fff;
  background: #363745;
}
.owl-dt-container .owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: none;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-header {
  color: #fff;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
  height: 0;
  padding: 0;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  display: none;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  font-size: 12px;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell {
  color: #fff;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-content {
  font-size: 12px;
  background: #363745;
  border-radius: 0;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background: #E04141;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border: 1px solid #E04141;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: none;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-container .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected):not(.owl-dt-calendar-cell-out) {
  background: #E04141;
}
.owl-dt-container .owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: rgba(255, 255, 255, 0.5);
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.visible-mobile {
  display: none !important;
}

@media (max-width: 700px) {
  .visible-mobile {
    display: inherit !important;
  }

  .hidden-mobile {
    display: none !important;
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 16px;
  padding: 0 18px;
  background: rgba(255, 255, 255, 0.1);
}
.progress-bar .progress {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 32px;
  background: rgba(254, 65, 65, 0.78);
  border-radius: 16px;
}
.progress-bar .text {
  display: flex;
  color: #fff;
}
.progress-bar .text .percent {
  width: 38px;
}
.progress-bar.highlight .progress {
  box-shadow: 0 2px 13px 0 #E04141;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.nav {
  display: flex;
  border-bottom: 1px solid #313241;
  overflow: auto;
}
.nav a:not(.icon) {
  position: relative;
  display: flex;
  align-items: center;
  padding: 22px 24px 20px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  color: #6F727C;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.nav a:not(.icon).active {
  color: #fff;
}
.nav a:not(.icon).active:before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 24px;
  right: 24px;
  height: 1px;
  background: #E04141;
}
.nav a:not(.icon):hover {
  color: #fff;
}

/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
.table .content {
  overflow: auto;
}
.table .table-row {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 64px;
}
.table .table-row.header {
  color: rgba(255, 255, 255, 0.5);
}
.table .table-row.header:hover .table-cell {
  background: #313241;
  color: rgba(255, 255, 255, 0.5);
}
.table .table-row.header .table-cell {
  background-color: #313241;
  text-align: center;
}
.table .table-row.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  overflow: auto;
  overflow-y: hidden;
}
.table .table-row.sticky::-webkit-scrollbar {
  display: none;
}
.table .table-row:nth-child(2n) .table-cell {
  background-color: #272836;
}
.table .table-row:hover .table-cell {
  background: #E04141;
  color: #fff;
}
.table .table-row .table-cell {
  padding: 12px 0;
  background: #313241;
  flex: 1 0;
}
.table .table-row .table-cell.sticky {
  position: sticky;
  left: 0;
  z-index: 1;
}

.table-new {
  display: flex;
  flex-direction: column;
}
.table-new.scroll {
  overflow-x: auto;
}
.table-new .table-row {
  display: flex;
}
.table-new .table-row.header .table-cell {
  position: relative;
  z-index: 1;
  font: 12px/16px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
}
.table-new .table-row.header .table-cell.sortable {
  cursor: pointer;
}
.table-new .table-row.header .table-cell.identity {
  position: relative;
  z-index: 2;
}
.table-new .table-row.header .table-cell.lead {
  font: 12px/16px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
}
.table-new .table-row:nth-child(2n+1) .table-cell {
  background-color: #313241;
}
.table-new .table-row .table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 68px;
  min-width: 90px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 12px 22px;
  background: #242531;
  text-align: center;
  border-right: 1px solid rgba(22, 23, 33, 0.4);
}
.table-new .table-row .table-cell:first-child {
  padding-left: 30px;
}
.table-new .table-row .table-cell:last-child {
  border-right: none;
}
.table-new .table-row .table-cell.lead {
  color: #E04141;
  font: bold 18px/24px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
}
.table-new .table-row .table-cell.identity {
  position: absolute;
  max-width: 305px;
  width: 305px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.table-new .table-row .table-cell.identity .number {
  flex-shrink: 0;
  width: 25px;
  margin-right: 10px;
  text-align: center;
}
.table-new .table-row .table-cell.identity .photo {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.table-new .table-row .table-cell.identity .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table-new .table-row .table-cell.identity .info {
  flex-grow: 1;
  overflow: hidden;
  font-weight: bold;
  line-height: 20px;
}
.table-new .table-row .table-cell.identity .info .name {
  width: 100%;
}
.table-new .table-row .table-cell.identity .info .text-color-muted {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}
.table-new .table-row .table-cell.identity a {
  color: #fff;
}
.table-new .table-row .table-cell.identity-hidden {
  max-width: 305px;
  width: 305px;
  background: transparent;
}
.table-new .table-row .table-cell .hidden-title {
  font: 12px/16px "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  opacity: 0;
  line-height: 0;
  height: 0;
}
.table-new .subtitle {
  position: sticky;
  left: 0;
  padding: 15px 0 10px 30px;
  color: #6F727C;
}

@media (max-width: 700px) {
  .table-new .table-row .table-cell.identity {
    width: 150px;
  }
  .table-new .table-row .table-cell.identity .photo {
    display: none;
  }
  .table-new .table-row .table-cell.identity .info {
    font-size: 12px;
  }
  .table-new .table-row .table-cell.identity-hidden {
    width: 150px;
  }
}
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
/* Layout */
/* Colors */
/* Bootstrap */
/* Device break points */
*:focus,
*:active {
  outline: none;
}

html {
  height: 100%;
  background: #161721;
}

body {
  min-height: 100%;
  background: #161721;
  font-size: 14px !important;
  line-height: 16px !important;
  -webkit-overflow-scrolling: touch;
}
body.dialog-opened {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  pointer-events: none;
}
body.no-scroll {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.cdk-global-scrollblock {
  bottom: 0;
  height: auto;
}

mtg-app, mtg-layout, mtg-header {
  display: block;
}

.croppie-container .cr-viewport, .croppie-container .cr-resizer {
  box-shadow: none !important;
}

.mtg-overlay-backdrop {
  background: rgba(22, 23, 33, 0.98);
}

.empty-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #313241;
  text-align: center;
  text-transform: uppercase;
}

.empty-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.empty-logo-basketball {
  background: url(/assets/empty-logo-basketball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-volleyball {
  background: url(/assets/empty-logo-volleyball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-hockey {
  background: url(/assets/empty-logo-hockey.png) no-repeat center;
  background-size: contain;
}

.empty-logo-football {
  background: url(/assets/empty-logo-football.png) no-repeat center;
  background-size: contain;
}

.empty-logo-handball {
  background: url(/assets/empty-logo-handball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-waterpolo {
  background: url(/assets/empty-logo-waterpolo.png) no-repeat center;
  background-size: contain;
}

.circle-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.dialog-content {
  position: relative;
  margin: auto;
}

.admin-layout {
  display: flex;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
}
.admin-layout > .content {
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding: 0 30px 0;
}

@media (max-width: 700px) {
  .admin-layout > .content {
    padding: 0 8px 0;
  }
}
.confetti {
  height: 5px;
  width: 5px;
  position: absolute;
  opacity: 0.75;
  z-index: 0;
  /* gpu acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.confetti.blue {
  background: #00a8ff;
}
.confetti.green {
  background: #4cd137;
}
.confetti.yellow {
  background: #fbc531;
}
.confetti.red {
  background: #e84118;
}
.confetti.pink {
  background: #ff9ff3;
}
.confetti.circle {
  border-radius: 50%;
}
.confetti.rectangle {
  padding: 0 1px;
}