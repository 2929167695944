@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bcdque');
  src:  url('fonts/icomoon.eot?bcdque#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bcdque') format('truetype'),
    url('fonts/icomoon.woff?bcdque') format('woff'),
    url('fonts/icomoon.svg?bcdque#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-waterpolo-cap:before {
  content: "\e961";
}
.icon-rugby:before {
  content: "\e95f";
}
.icon-handball:before {
  content: "\e95c";
}
.icon-hockey-stick:before {
  content: "\e959";
}
.icon-hockey-sticks:before {
  content: "\e95d";
}
.icon-hockey-puck:before {
  content: "\e95e";
}
.icon-Burger:before {
  content: "\e958";
}
.icon-enter:before {
  content: "\e957";
}
.icon-facebook:before {
  content: "\e956";
}
.icon-subtract:before {
  content: "\e950";
}
.icon-search:before {
  content: "\e913";
}
.icon-timeout:before {
  content: "\e94f";
}
.icon-send:before {
  content: "\e939";
}
.icon-basketball:before {
  content: "\e93a";
}
.icon-link:before {
  content: "\e93b";
}
.icon-team-circle:before {
  content: "\e93c";
}
.icon-info:before {
  content: "\e93d";
}
.icon-men:before {
  content: "\e93e";
}
.icon-date:before {
  content: "\e93f";
}
.icon-volleyball:before {
  content: "\e940";
}
.icon-bell-new:before {
  content: "\e941";
}
.icon-pencil:before {
  content: "\e942";
}
.icon-mail-new:before {
  content: "\e943";
}
.icon-like-done:before {
  content: "\e944";
}
.icon-like:before {
  content: "\e945";
}
.icon-full-size-small:before {
  content: "\e946";
}
.icon-full-size:before {
  content: "\e947";
}
.icon-media-add:before {
  content: "\e948";
}
.icon-trash-new:before {
  content: "\e949";
}
.icon-close-circle:before {
  content: "\e94a";
}
.icon-video:before {
  content: "\e94b";
}
.icon-vs:before {
  content: "\e94c";
}
.icon-media:before {
  content: "\e94d";
}
.icon-turn-device:before {
  content: "\e94e";
}
.icon-star-outline:before {
  content: "\e937";
}
.icon-star:before {
  content: "\e938";
}
.icon-color:before {
  content: "\e936";
}
.icon-change:before {
  content: "\e935";
}
.icon-next:before {
  content: "\e933";
}
.icon-finish:before {
  content: "\e934";
}
.icon-settings:before {
  content: "\e928";
}
.icon-mic-disabled:before {
  content: "\e929";
}
.icon-alert:before {
  content: "\e927";
}
.icon-kebab:before {
  content: "\e926";
}
.icon-flag:before {
  content: "\e925";
}
.icon-load:before {
  content: "\e923";
}
.icon-mask:before {
  content: "\e924";
}
.icon-await:before {
  content: "\e92b";
}
.icon-doc:before {
  content: "\e92c";
}
.icon-insta:before {
  content: "\e92d";
}
.icon-lock:before {
  content: "\e92e";
}
.icon-no-leagues:before {
  content: "\e92f";
}
.icon-star-gold:before {
  content: "\e930";
}
.icon-timer:before {
  content: "\e931";
}
.icon-web:before {
  content: "\e932";
}
.icon-mail:before {
  content: "\e91f";
}
.icon-phone:before {
  content: "\e920";
}
.icon-share:before {
  content: "\e921";
}
.icon-pin:before {
  content: "\e922";
}
.icon-flash:before {
  content: "\e92a";
}
.icon-trash:before {
  content: "\e91d";
}
.icon-location:before {
  content: "\e91b";
}
.icon-calendar-add:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e919";
}
.icon-eye-slash:before {
  content: "\e917";
}
.icon-eye:before {
  content: "\e918";
}
.icon-vk:before {
  content: "\e916";
}
.icon-chevron-left:before {
  content: "\e915";
}
.icon-camera:before {
  content: "\e914";
}
.icon-add:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-award-left:before {
  content: "\e902";
}
.icon-award-right:before {
  content: "\e903";
}
.icon-captain:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-empty-polls:before {
  content: "\e907";
}
.icon-emty-poll:before {
  content: "\e908";
}
.icon-exit:before {
  content: "\e909";
}
.icon-image:before {
  content: "\e90a";
}
.icon-more:before {
  content: "\e90b";
}
.icon-notification:before {
  content: "\e90c";
}
.icon-ok:before {
  content: "\e90d";
}
.icon-refresh:before {
  content: "\e90e";
}
.icon-select:before {
  content: "\e90f";
}
.icon-statistics:before {
  content: "\e910";
}
.icon-team:before {
  content: "\e911";
}
.icon-user:before {
  content: "\e912";
}
.icon-waterpolo:before {
  content: "\e960";
}
.icon-beach-volleyball:before {
  content: "\e962";
}
.icon-football:before {
  content: "\e95a";
}
.icon-football-mini:before {
  content: "\e95b";
}
.icon-3x3:before {
  content: "\e951";
}
.icon-stage:before {
  content: "\e952";
}
.icon-table-view-2:before {
  content: "\e953";
}
.icon-table-view:before {
  content: "\e954";
}
.icon-brackets:before {
  content: "\e955";
}
.icon-ok-check:before {
  content: "\e91a";
}
.icon-repeat:before {
  content: "\e984";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-table2:before {
  content: "\ea71";
}
.icon-mic:before {
  content: "\e91e";
}
.icon-cog:before {
  content: "\e994";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-share1:before {
  content: "\ea7d";
}
