/* Layout */
$headerHeight: 64px;
$sidebarWidth: 80px;
$maxContentWidth: 1216px;
$contentSidePadding: 30px;
$layoutZIndex: 100;

/* Colors */
$mainBackgroundColor: #161721;
$secondaryBackgroundColor: #222330;
$inputBackgroundColor: #2B2C38;
$lightGreyBackgroundColor: #2C2D3C;
$table-border-color: #363745;
$textColor: #fff;
$textMutedColor: rgba(255, 255, 255, .5);
$textGrayColor: #6F727C;
$primaryColor: #E04141;

$nightColor: #161721;
$carbonColor: #242531;
$graphiteColor: #313241;
$pebbleColor: #525562;
$greyColor: #6F727C;

$alertColor: #C82A2A;
$poppyColor: #E04141;
$coralColor: #FF605F;

/* Bootstrap */
$font-family-sans-serif: "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;
$font-weight-base: normal;
$font-size-base: 14px;
$line-height-base: 16px;
$body-color: $textColor;
$body-bg: $mainBackgroundColor;

/* Device break points */
$tabletBreakpoint: 1050px;
$phoneBreakpoint: 700px;
$smallPhoneBreakpoint: 400px;
