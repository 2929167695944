@import "variables";

.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -13px;
  .col {
    padding: 0 13px;
  }
}
.form-control {
  margin: 0 0 25px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (max-width: $tabletBreakpoint) {
  .form-row {
    flex-direction: column;
    .col {
      flex: 1 auto;
      width: 100%;
    }
  }
}
