@import "variables";
@import "mixins";

$tableRowHeight: 64px;
$headerBackground: $graphiteColor;

.table {
  .content {
    overflow: auto;
  }
  .table-row {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 64px;
    &.header {
      color: $textMutedColor;
      &:hover .table-cell {
        background: $graphiteColor;
        color: $textMutedColor;
      }
      .table-cell {
        background-color: $graphiteColor;
        text-align: center;
      }
    }
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 2;
      overflow: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &:nth-child(2n) {
      .table-cell {
        background-color: #272836;
      }
    }
    &:hover {
      .table-cell {
        background: $primaryColor;
        color: #fff;
      }
    }
    .table-cell {
      padding: 12px 0;
      background: $graphiteColor;
      flex: 1 0;
      &.sticky {
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }
  }
}


.table-new {
  display: flex;
  flex-direction: column;

  &.scroll {
    overflow-x: auto;
  }

  .table-row {
    display: flex;
    &.header {
      .table-cell {
        position: relative;
        z-index: 1;
        @include smallText();
        font-weight: bold;

        &.sortable {
          cursor: pointer;
        }

        &.identity {
          position: relative;
          z-index: 2;
        }

        &.lead {
          @include smallText();
          font-weight: bold;
        }
      }

      &.stick {
        .table-cell.identity {
          //position: fixed;
          //top: 64px;
        }
      }
    }

    &:nth-child(2n + 1) {
      .table-cell {
        background-color: $graphiteColor;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 68px;
      min-width: 90px;
      //max-width: 125px;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 12px 22px;
      background: $carbonColor;
      text-align: center;
      border-right: 1px solid rgba($nightColor, .4);
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        border-right: none;
      }
      &.lead {
        color: $poppyColor;
        @include h5();
        font-weight: normal;
      }
      &.identity {
        position: absolute;
        max-width: 305px;
        width: 305px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: row;
        text-align: left;
        .number {
          flex-shrink: 0;
          width: 25px;
          margin-right: 10px;
          text-align: center;
        }
        .photo {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          flex-grow: 1;
          overflow: hidden;
          font-weight: bold;
          line-height: 20px;
          .name {
            width: 100%;
          }
          .text-color-muted {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: normal;
          }
        }
        a {
          color: $textColor;
        }
      }

      &.identity-hidden {
        max-width: 305px;
        width: 305px;
        background: transparent;
      }

      .hidden-title {
        @include smallText();
        font-weight: bold;
        opacity: 0;
        line-height: 0;
        height: 0;
      }
    }
  }

  .subtitle {
    position: sticky;
    left: 0;
    padding: 15px 0 10px 30px;
    color: $greyColor;
  }
}

@media (max-width: $phoneBreakpoint) {
  .table-new {
    .table-row {
      .table-cell {
        &.identity {
          width: 150px;

          .photo {
            display: none;
          }

          .info {
            font-size: 12px;
          }
        }

        &.identity-hidden {
          width: 150px;
        }
      }
    }
  }
}
