@import "variables";

.nav {
  display: flex;
  border-bottom: 1px solid $graphiteColor;
  overflow: auto;
  a:not(.icon) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 22px 24px 20px;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: #6F727C;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
    &.active {
      color: #fff;
      &:before {
        content: '';
        position: absolute;;
        bottom: -1px;
        left: 24px;
        right: 24px;
        height: 1px;
        background: $poppyColor;
      }
    }
    &:hover {
      color: #fff;
    }
  }
}
