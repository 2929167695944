@import "variables";
@import "mixins";

*:focus,
*:active {
  outline: none;
}
html {
  height: 100%;
  background: $mainBackgroundColor;
}
body {
  min-height: 100%;
  background: $mainBackgroundColor;
  font-size: 14px!important;
  line-height: 16px!important;
  -webkit-overflow-scrolling: touch;
  &.dialog-opened {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    pointer-events: none;
    mtg-app {

    }
  }
  &.no-scroll {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
}
.cdk-global-scrollblock {
  bottom: 0;
  height: auto;
}

mtg-app, mtg-layout, mtg-header { display: block; }
.croppie-container .cr-viewport, .croppie-container .cr-resizer {
  box-shadow: none!important;
}

.mtg-overlay-backdrop {
  background: rgba(22, 23, 33, 0.98);
}

.empty-photo {
  @include centered-flex();
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $graphiteColor;
  text-align: center;
  text-transform: uppercase;
}

.empty-logo {
  position: relative;
  @include centered-flex();
  width: 100%;
  height: 100%;
}

.empty-logo-basketball {
  background: url(/assets/empty-logo-basketball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-volleyball {
  background: url(/assets/empty-logo-volleyball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-hockey {
  background: url(/assets/empty-logo-hockey.png) no-repeat center;
  background-size: contain;
}
.empty-logo-football {
  background: url(/assets/empty-logo-football.png) no-repeat center;
  background-size: contain;
}
.empty-logo-handball {
  background: url(/assets/empty-logo-handball.png) no-repeat center;
  background-size: contain;
}
.empty-logo-waterpolo {
  background: url(/assets/empty-logo-waterpolo.png) no-repeat center;
  background-size: contain;
}


.circle-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.dialog-content {
  position: relative;
  margin: auto;
}

// TODO move to component
.admin-layout {
  display: flex;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;

  > .content {
    width: 100%;
    max-width: $maxContentWidth;
    margin: 0 auto;
    padding: 0 $contentSidePadding 0;
  }
}

@media (max-width: $phoneBreakpoint) {
  .admin-layout {
    > .content {
      padding: 0 8px 0;
    }
  }
}
