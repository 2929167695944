@import 'variables';

.data-list {
  display: flex;
  flex-direction: column;
  > .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 30px;
    &:hover {
      background-color: $primaryColor;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .data-list {
    > .item {
      padding: 13px 20px;
    }
  }
}
