@import 'variables';

.badge {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: $primaryColor;
  color: $textColor;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
